import React, { memo } from 'react';

import './footer-container.scss';
import { Footer } from '@on-haiti/shared-ui/components';

const FooterContainer = ({ metadata }) => {
  return metadata.map((footer) => <Footer key={footer.id} metadata={footer} />);
};

export default memo(FooterContainer);
