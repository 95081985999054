export default {
  headers: [
    {
      id: 'mainHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/ilovik/logo.png',
        url: 'https://www.cadyjax.com/',
      },
      breakpoint: 800,
      desktopMenu: {
        type: 'center',
        disableOnHoverMenuExpand: true,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-ilovik-desktop-header',
        headerSectionsClassName: 'on-ilovik-desktop-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-ilovik-desktop-item',
        menuClassName: 'on-ilovik-desktop-menu',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-ilovik-desktop-menu-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'home-item',
            content: 'Home',
            url: 'https://www.cadyjax.com/',
          },
          {
            id: 'search-item',
            content: 'Search',
            menuItems: [
              {
                id: 'search-item-agents',
                content: 'Agents',
                url: 'https://www.cadyjax.com/agents/',
              },
              {
                id: 'search-item-map',
                content: 'Map Search',
                url: 'https://www.cadyjax.com/results-map/',
              },
              {
                id: 'search-item-listings',
                content: 'Our Listings',
                url: 'https://www.cadyjax.com/results-gallery/?userID=all',
              },
            ],
          },
          {
            id: 'buy-item',
            content: 'Buying',
            url: 'https://www.cadyjax.com/buy',
          },
          {
            id: 'financing-item',
            content: 'Financing',
            url: 'https://www.cadyjax.com/finance',
          },
          {
            id: 'sell-item',
            content: 'Selling',
            menuItems: [
              {
                id: 'sell-item-home',
                content: 'Sell Your Home',
                url: 'https://www.cadyjax.com/sell/',
              },
              {
                id: 'sell-item-home-worth',
                content: "What's My Home Worth?",
                url: 'https://www.cadyjax.com/home-valuation/',
              },
            ],
          },
          {
            id: 'resource-item',
            content: 'Resources',
            menuItems: [
              {
                id: 'resource-item-blog',
                content: 'Blog',
                url: 'https://www.cadyjax.com/blog/',
              },
              {
                id: 'resource-item-community',
                content: 'Community Guides',
                url: 'https://www.cadyjax.com/guides/',
              },
              {
                id: 'resource-item-additional',
                content: 'Additional Resources',
                url: 'https://www.cadyjax.com/resources/',
              },
            ],
          },
          {
            id: 'contact-item',
            content: 'Contact Us',
            url: 'https://www.cadyjax.com/contactus',
          },
          {
            id: 'trade-in-item',
            content: 'Trade In',
            url: 'https://trade-in-ilovik.netlify.app',
          },
          {
            id: 'cash-offer-item',
            content: 'Cash Offer',
            url: 'https://cash-offer-ilovik.netlify.app',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-ilovik-mobile-header-logo-container',
          imageClassName: 'on-ilovik-mobile-header-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          className: '',
          hide: false,
          type: 'hamburger',
          reverse: false,
        },
        headerClassName: 'on-ilovik-mobile-header',
        headerClassNameOverride: '',
        mobileMenuClassName: 'on-ilovik-mobile-menu',
        itemClassName: 'on-ilovik-mobile-item',
        menuClassName: 'on-ilovik-mobile-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-ilovik-mobile-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'home-item',
            content: 'Home',
            url: 'https://www.cadyjax.com/',
          },
          {
            id: 'search-item',
            content: 'Search',
            menuItems: [
              {
                id: 'search-item-agents',
                content: 'Agents',
                url: 'https://www.cadyjax.com/agents/',
              },
              {
                id: 'search-item-map',
                content: 'Map Search',
                url: 'https://www.cadyjax.com/results-map/',
              },
              {
                id: 'search-item-listings',
                content: 'Our Listings',
                url: 'https://www.cadyjax.com/results-gallery/?userID=all',
              },
            ],
          },
          {
            id: 'buy-item',
            content: 'Buying',
            url: 'https://www.cadyjax.com/buy',
          },
          {
            id: 'financing-item',
            content: 'Financing',
            url: 'https://www.cadyjax.com/finance',
          },
          {
            id: 'sell-item',
            content: 'Selling',
            menuItems: [
              {
                id: 'sell-item-home',
                content: 'Sell Your Home',
                url: 'https://www.cadyjax.com/sell/',
              },
              {
                id: 'sell-item-home-worth',
                content: "What's My Home Worth?",
                url: 'https://www.cadyjax.com/home-valuation/',
              },
            ],
          },
          {
            id: 'resource-item',
            content: 'Resources',
            menuItems: [
              {
                id: 'resource-item-blog',
                content: 'Blog',
                url: 'https://www.cadyjax.com/blog/',
              },
              {
                id: 'resource-item-community',
                content: 'Community Guides',
                url: 'https://www.cadyjax.com/guides/',
              },
              {
                id: 'resource-item-additional',
                content: 'Additional Resources',
                url: 'https://www.cadyjax.com/resources/',
              },
            ],
          },
          {
            id: 'contact-item',
            content: 'Contact Us',
            url: 'https://www.cadyjax.com/contactus',
          },
          {
            id: 'trade-in-item',
            content: 'Trade In',
            url: 'https://trade-in-ilovik.netlify.app',
          },
          {
            id: 'cash-offer-item',
            content: 'Cash Offer',
            url: 'https://cash-offer-ilovik.netlify.app',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-ilovik-footer-first',
      display: true,
      className: 'on-ilovik-footer-section pt-2',
      sectionClassName: 'on-ilovik-footer-section-container',
      columns: [
        {
          id: 'col-one',
          className: 'on-ilovik-footer-column align-left col-xl-2 col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-one-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'Guides',
            },
            {
              id: 'col-one-menu',
              type: 'menu',
              className: 'on-ilovik-footer-menu',
              itemClassName: 'on-ilovik-footer-menu-item',
              style: {},
              items: [
                {
                  id: 'col-one-item-one',
                  data: 'Riverside / Avondale',
                  url: 'https://www.cadyjax.com/guide/riverside-avondale/',
                },
                {
                  id: 'col-one-item-two',
                  data: 'St Johns',
                  url: 'https://www.cadyjax.com/guide/st-johns/',
                },
                {
                  id: 'col-one-item-three',
                  data: 'Mandarin',
                  url: 'https://www.cadyjax.com/guide/mandarin/',
                },
                {
                  id: 'col-one-item-four',
                  data: 'Nocatee',
                  url: 'https://www.cadyjax.com/guide/nocatee/',
                },
                {
                  id: 'col-one-item-five',
                  data: 'Ponte Vedra',
                  url: 'https://www.cadyjax.com/guide/ponte-vedra/',
                },
                {
                  id: 'col-one-item-six',
                  data: 'More Guides >',
                  url: 'https://www.cadyjax.com/guides/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-two',
          className: 'on-ilovik-footer-column align-left col-xl-2 col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'Company',
            },
            {
              id: 'col-two-menu',
              type: 'menu',
              className: 'on-ilovik-footer-menu',
              style: {},
              items: [
                {
                  id: 'col-two-item-one',
                  data: 'Meet The Team',
                  url: 'https://www.cadyjax.com/agents/',
                },
                {
                  id: 'col-two-item-two',
                  data: 'Our Blog',
                  url: 'https://www.cadyjax.com/blog/',
                },
                {
                  id: 'col-two-item-three',
                  data: 'Contact Us',
                  url: 'https://www.cadyjax.com/contactus//',
                },
              ],
            },
          ],
        },
        {
          id: 'col-three',
          className: 'on-ilovik-footer-column align-left col-xl-2 col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-three-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'Resources',
            },
            {
              id: 'col-three-menu',
              type: 'menu',
              className: 'on-ilovik-footer-menu',
              style: {},
              items: [
                {
                  id: 'col-three-item-one',
                  data: 'Buy a Home',
                  url: 'https://www.cadyjax.com/buy/',
                },
                {
                  id: 'col-three-item-two',
                  data: 'Sell Your Home',
                  url: 'https://www.cadyjax.com/sell/',
                },
                {
                  id: 'col-three-item-three',
                  data: 'Finance',
                  url: 'https://www.cadyjax.com/finance/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-four',
          className: 'on-ilovik-footer-column align-left col-xl-2 col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-four-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'Get Social',
            },
            {
              id: 'col-four-socials',
              type: 'socialIcons',
              className: 'on-ilovik-footer-socials',
              itemClassName: 'on-ilovik-footer-socials-item',
              style: {},
              items: [
                {
                  id: 'col-four-facebook',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook',
                  url: 'https://www.facebook.com/CadyRealty/',
                },
                {
                  id: 'col-four-twitter',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'twitter',
                  url: 'https://twitter.com/cadyrealty',
                },
                {
                  id: 'col-four-instagram',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram-square',
                  url: 'https://www.instagram.com/cadyrealty/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-five',
          className: 'on-ilovik-footer-column align-left col-xl-4 col-lg-4 col-sm-24 pt-md-5 pt-lg-1 pt-xl-1',
          style: {},
          items: [
            {
              id: 'col-five-heading-one',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'About Us',
            },
            {
              id: 'col-five-heading-two',
              type: 'heading',
              elementType: 'p',
              className: '',
              style: {},
              data: "Compass is Greater Jacksonville's most innovative real estate team.",
            },
            {
              id: 'col-five-heading-three',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: 'Compass',
            },
            {
              id: 'col-five-heading-four',
              type: 'heading',
              elementType: 'p',
              className: '',
              style: {},
              data: '3568 St Johns Ave, Jacksonville, FL 32205',
            },
            {
              id: 'col-six-phone',
              type: 'socialIcons',
              className: '',
              items: [
                {
                  id: 'phone',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'phone',
                  url: 'tel:(904)327-5783',
                  data: ' (904) 327-5783',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-ilovik-footer-second',
      display: true,
      className: 'on-ilovik-footer-section',
      sectionClassName: 'on-ilovik-footer-section-container',
      columns: [
        {
          id: 'disclaimer-column',
          className: 'on-ilovik-footer-row-disclaimer align-left pt-3',
          style: {},
          items: [
            {
              id: 'disclaimer-data',
              type: 'heading',
              className: 'pt-3',
              style: {},
              data: '© 2022 Northeast Florida Multiple Listing Service, Inc. All rights reserved. The data relating to real estate for sale on this web site comes in part from the Internet Data Exchange (IDX) program of the Northeast Florida Multiple Listing Service, Inc. Real estate listings held by brokerage firms other than Compass are marked with the listing broker’s name and detailed information about such listings includes the name of the listing brokers. Data provided is deemed reliable but is not guaranteed. Data last updated 2022-05-02T00:25:42.663.',
            },
            {
              id: 'disclaimer-copyright',
              type: 'heading',
              className: 'pt-2 pb-2',
              style: {},
              data: 'Digital Millennium Copyright Act',
            },
          ],
        },
      ],
    },
    {
      id: 'on-ilovik-footer-third',
      display: true,
      className: 'on-ilovik-footer-section-last',
      sectionClassName: 'on-ilovik-footer-section-container-last',
      columns: [
        {
          id: 'disclaimer-column',
          className: 'on-ilovik-footer-row-privacy align-left',
          style: {},
          items: [
            {
              id: 'boomtown-logo',
              type: 'image',
              className: 'footer-link-item-first ps-0',
              alt: 'logo',
              linkUrl: 'https://si-homelight.s3.amazonaws.com/sites/ilovik/boomtown-footer.png',
              url: 'https://www.cadyjax.com/wp-content/themes/wp-base-theme/assets/media/build/boomtown-footer.png',
              style: { width: '80px' },
            },
            {
              id: 'footer-2022',
              type: 'heading',
              className: 'footer-link-item-first',
              data: '© 2022',
              url: 'https://boomtownroi.com/?utm_campaign=cadyjax.com&utm_medium=bt_client_site_referral&utm_source=client_sites',
            },
            {
              id: 'footer-terms',
              type: 'heading',
              className: '',
              data: 'Terms of Use',
              url: 'https://www.cadyjax.com/terms',
            },
            {
              id: 'footer-privacy',
              type: 'heading',
              className: '',
              data: 'Privacy Policy',
              url: 'https://www.cadyjax.com/privacy/',
            },
            {
              id: 'footer-access',
              type: 'heading',
              className: '',
              data: 'Accessibility',
              url: 'https://www.cadyjax.com/accessibility/',
            },
            {
              id: 'footer-DMCA',
              type: 'heading',
              className: '',
              data: 'DMCA',
              url: 'https://www.cadyjax.com/dmca/',
            },
            {
              id: 'footer-sitemap',
              type: 'heading',
              className: '',
              data: 'Listings Sitemap',
              url: 'https://www.cadyjax.com/sitemap/',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'Cady at Compass',
    agentDescription: 'Top agents in Jacksonville, FL',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/ilovik/agent.png',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/ilovik/hero-bg.jpg',
    expressFee: '1.5%',
  },
};
