import React, { memo } from 'react';

import './header-container.scss';
import { Header } from '@on-haiti/shared-ui/components';

const HeaderContainer = ({ metadata }) => {
  return metadata.map((header) => <Header key={header.id} metadata={header} />);
};

export default memo(HeaderContainer);
